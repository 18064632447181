import React, { useMemo, useState, useEffect } from "react";
import logo from "../../assets/logo/logo.svg";
import arrow from "../../assets/icons/arrow.svg";
import twitter from "../../assets/logo/twitter.svg";
import { Button } from "../shared/button";
import { TileGrid } from "./tile-grid";
import PositionsSmall from "../../assets/background/positions-small.png";
import PositionsMedium from "../../assets/background/positions-medium.png";
import axios from "axios";
import loader from "../../animations/loader.json";
import Lottie from "lottie-react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

export const Hero = () => {
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [email, setEmail] = useState("");
  const [accessToken, setAccessToken] = useState(null);
  const [loginStatus, setLoginStatus] = useState(null);
  const [disable, setDisabled] = useState(false);
  const [remainingTime, setRemainingTime] = useState(60);
  const [userId, setUserId] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const handleJoinWaitlist = async () => {
    try {
      setShowLoader(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/join-waitlist`,
        {
          email: email,
        }
      );
      setUserId(response.data.user._id);
      setEmailSubmitted(true);
      toast.success("Joined Waitlist");
    } catch (error) {
      toast.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleLogin = async () => {
    try {
      setShowLoader(true);
      window.open(
        `${process.env.REACT_APP_API_URL}/twitter/login`,
        "_blank",
        "width=600,height=700"
      );

      setCurrentStep(currentStep + 1);
      toast.success("Connected");
    } catch (error) {
      toast.error("Error initiating Twitter login", error);
    } finally {
      setShowLoader(false);
    }
  };

  const steps = useMemo(
    () => [
      { text: "Enter Alpha", onClick: () => setCurrentStep(currentStep + 1) },
      {
        text: "Connect Twitter",
        onClick: () => handleLogin(),
        description: "Follow Positions on X to start earning $PP",
      },
      {
        text: "Follow us on Twitter",
        onClick: () => followTwitter(),
        description: "Follow Positions & Tweet to start earning $PP",
      },
      {
        text: disable ? `Verifying... (${remainingTime}s)` : "Verify",
        onClick: () =>
          toast.success("Verified") && setCurrentStep(currentStep + 1),
        description: "Verification in progress",
      },
      {
        text: "Share with frens",
        onClick: () => shareTweet(),
        description: "Follow Positions & Tweet to start earning $PP",
      },
      {
        text: disable ? `Verifying... (${remainingTime}s)` : "Verify",
        onClick: () => verifyTweet() && toast.success("Tweet is verified"),
        description: "Follow Positions & Tweet to start earning $PP",
      },
      // {
      //   text: "Join Discord",
      //   onClick: () => {},
      //   description: "You've earned 50 $PP, Stay updated.",
      // },
    ],
    [disable, remainingTime, currentStep]
  );

  const followTwitter = () => {
    window.open("https://x.com/PositionsFi", "_blank");
    setCurrentStep(currentStep + 1);
    startTimer();
  };

  const shareTweet = () => {
    window.open(
      "https://twitter.com/intent/tweet?text=gm%2C%20i've%20signed%20up%20for%20the%20testnet%20of%20%40PositionsFi",
      "_blank"
    );
    setCurrentStep(currentStep + 1);
    startTimer();
  };

  const verifyTweet = async () => {
    try {
      setShowLoader(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/verify-tweet`,
        {
          access_token: accessToken,
          userId,
        }
      );

      // setCurrentStep(currentStep + 1);
    } catch (error) {
      toast.error(error);
    } finally {
      setShowLoader(false);
    }
  };

  const startTimer = () => {
    setDisabled(true);
    setRemainingTime(60);
    const timer = setInterval(() => {
      setRemainingTime((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          setDisabled(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== `${process.env.REACT_APP_API_URL}`) return;

      const { token, status } = event.data;

      if (token) {
        setAccessToken(token);
        setLoginStatus(status);
      }
    };

    window.addEventListener("message", handleMessage);
  }, [loginStatus]);

  return (
    <section className="flex flex-col h-screen w-full bg-black">
      <TileGrid className="-left-60 -top-60 absolute invisible lg:block" />

      <img
        src={PositionsSmall}
        alt="Small Screen Background"
        className="block lg:hidden absolute h-screen inset-0 w-full object-cover"
      />
      <img
        src={PositionsMedium}
        alt="Small Screen Background"
        className="block lg:hidden  absolute h-screen inset-0 w-full object-cover"
      />

      <div className="flex flex-col h-full justify-center items-center gap-y-6 font-hybrid-medium">
        <div className="relative p-2 flex flex-col gap-y-6 items-center">
          <img className="" src={logo} alt="Positions" />
          <p className="text-white lg:text-4xl text-2xl text-center sm:text2xl">
            Liquidity <span className="text-tangerine">(re)Enabling</span>{" "}
            Protocol
          </p>
          <div>
            <Link to={"https://testnet.positions.finance/"}>
              <button className="bg-tangerine text-black px-4 py-3 flex gap-x-4 items-center justify-center lg:text-base text-sm hover:bg-tangerine/90">
                Enter Testnet
                <img
                  src={arrow}
                  alt="side-arrow"
                  className="group-hover:fill-white h-4 w-5"
                />
              </button>
            </Link>
          </div>
          {/* <div className="sm:flex-col sm:flex">
            {showLoader ? (
              <Lottie
                animationData={loader}
                // className="h-20 w-20 sm:h-20 sm:w-20"
              />
            ) : !emailSubmitted ? (
              <form
                className="flex flex-col sm:flex-row gap-y-4 md:w-full justify-center items-center gap-x-3"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleJoinWaitlist();
                }}
              >
                <input
                  required
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email Address"
                  className="bg-primary-gray font-medium text-secondary-gray pl-2.5 py-3.5 lg:w-80 w-40 focus:outline-none text-sm border border-tertiary-gray"
                />

                <button
                  type="submit"
                  className="bg-tangerine text-black px-4 py-3 flex gap-x-4 items-center justify-center lg:text-base text-sm hover:bg-tangerine/90"
                >
                  Join Waitlist
                  <img
                    src={arrow}
                    alt="side-arrow"
                    className="group-hover:fill-white h-4 w-5"
                  />
                </button>
              </form>
            ) : (
              <Button
                disabled={disable}
                text={steps[currentStep - 1].text}
                onClick={steps[currentStep - 1].onClick}
                description={steps[currentStep - 1].description}
              />
            )}
          </div> */}
        </div>
      </div>

      <div className="absolute bottom-0 left-0 w-full lg:p-16 p-3 flex justify-between items-center z-10">
        <a href="https://x.com/PositionsFi">
          <button className="text-white flex bg-primary-gray w-max lg:p-4 p-2 gap-x-2 md:text-base text-sm font-medium items-center">
            <img
              src={twitter}
              alt="twitter"
              width={18}
              height={18}
              className="md:h-5 md:w-5 h-4 w-4"
            />
            @positionsfi
          </button>
        </a>
        <div className="flex items-center gap-x-3">
          <div className="h-6 w-6 bg-tangerine"></div>
          <div className="h-6 w-6 bg-citrine"></div>
          <div className="h-6 w-6 bg-emerald"></div>
          <div className="h-6 w-6 bg-cerulean"></div>
        </div>
      </div>
    </section>
  );
};
