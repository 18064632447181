import React, { useEffect } from "react";
import positionsTiled from "../../assets/logo/poisitions-tiled.svg";

export const TileGrid = () => {
  useEffect(() => {
    const container = document.querySelector("#container");
    const tile = document.querySelector("#tile");

    for (let i = 0; i < 1599; i++) {
      container.appendChild(tile.cloneNode(true));
    }
  }, []);

  return (
    <div className="relative hidden lg:block">
      <div id="container">
        <div id="tile" className="tile"></div>
      </div>
      {/* <img
        className="absolute inset-0 z-10 top-20 left-56 right-12 skew-y-8 rotate-3 h-[330px] w-[330px] origin-center"
        src={positionsTiled}
        alt="positions"
      /> */}
    </div>
  );
};
